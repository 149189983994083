<template>
    <v-container>
        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="8" md="4" xl="2">

                <div v-show="stage === 'auth'">
                    <p>Для входа нужно указать номер телефона.</p>

                    <v-form lazy-validation id="usernameForm" ref="usernameForm" v-model="usernameFormValid">
                        <v-text-field
                            v-model="username"
                            v-mask="usernameFieldMask"
                            autocomplete="username"
                            clearable
                            id="user-text-field"
                            inputmode="tel"
                            label="Номер телефона"
                            outlined
                            type="tel"
                            :rules="[rules.required]"
                        />
                    </v-form>

                    <v-btn
                        block
                        color="primary"
                        :disabled="!usernameFormValid || sendBtnDisabled"
                        @click="sendCode()"
                    >
                        Выслать код
                    </v-btn>

                    <div class="text-center mt-6">
                        <div
                            class="grey--text"
                            style="cursor: pointer"
                            @click="isShownPrivacyPolicyDialog = !isShownPrivacyPolicyDialog"
                        >
                            Политика конфиденциальности
                        </div>
                    </div>

                    <AppVDialog
                        type="full-screen"
                        :isShowed.sync="isShownPrivacyPolicyDialog"
                        title="Политика конфиденциальности"
                    >
                        <v-container>
                            <AppPrivacyPolicy/>
                        </v-container>
                    </AppVDialog>
                </div>

                <div v-show="stage === 'oneTimeCode'">
                    <p>На номер {{ username }}, был выслан код.</p>

                    <v-form lazy-validation id="oneTimeCodeForm" ref="oneTimeCodeForm" v-model="oneTimeCodeFormValid">
                        <v-text-field
                            v-model.number="oneTimeCode"
                            v-mask="oneTimeCodeMask"
                            autocomplete="one-time-code"
                            clearable
                            counter
                            id="single-factor-code-text-field"
                            inputmode="numeric"
                            max="6"
                            maxlength="6"
                            label="Код"
                            outlined
                            pattern="[0-9]*"
                            step="1"
                            type="number"
                            :rules="[rules.required, rules.length]"
                        />
                    </v-form>

                    <v-btn
                        block
                        color="primary"
                        :disabled="!oneTimeCodeFormValid || loginBtnDisabled"
                        @click="login()"
                    >
                        Войти
                    </v-btn>

                    <div>
                        <div
                            v-if="oneTimeCodeResendTimer"
                            class="text-center grey--text mt-6"
                        >
                            Выслать код повторно через: {{ oneTimeCodeResendTimerComputed }}.
                        </div>
                        <v-btn
                            v-else
                            block
                            class="mt-3"
                            color="secondary"
                            text
                            @click="sendCode()"
                        >
                            Отправить код
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {
        VBtn,

        VContainer,
        VRow,
        VCol,

        VForm,
        VTextField,
    } from 'vuetify/lib'
    import AppVDialog from '@/components/vuetify/Dialog'

    import AppPrivacyPolicy from '@/components/app/privacy-policy/PrivacyPolicy'

    import {
        cloneDeep as _cloneDeep,
    } from 'lodash'

    import moment from 'moment'

    import { mask } from 'vue-the-mask'

    import { mapGetters, mapMutations } from 'vuex'

    import * as AuthService from '../services/auth.service'

    const oneTimeCodeLength = 6;

    export default {
        name: 'Auth',

        components: {
            VBtn,

            AppVDialog,

            VContainer,
            VRow,
            VCol,

            VForm,
            VTextField,

            AppPrivacyPolicy,
        },

        directives: {
            mask,
        },

        data() {
            return {
                stage: 'auth',

                isShownPrivacyPolicyDialog: false,

                username: '',
                usernameFormValid: true,
                usernameFieldMask: '+7 (###) ### ## ##',

                oneTimeCode: '',
                oneTimeCodeFormValid: true,
                oneTimeCodeMask: '######',
                oneTimeCodeResendTimer: undefined,

                rules: {
                    required: value => !!value || 'Обязательное поле',
                    length: value => value && value.toString().length === oneTimeCodeLength || `Минимум ${oneTimeCodeLength} символов`,
                },

                sendBtnDisabled: false,
                loginBtnDisabled: false,
            }
        },

        computed: {
            appBarData() {
                let title = null
                let navigationIcon = null

                if (this.stage === 'oneTimeCode') {
                    navigationIcon = {
                        type: 'back',
                        click: () => this.setStage('auth')
                    }
                } else {
                    title = 'Авторизация'
                }

                return {
                    color: 'white',
                    light: true,
                    navigationIcon,
                    title,
                }
            },

            clearUsername() {
                let username = _cloneDeep(this.username)

                return username ? username.replace(/[^0-9]/g, '') : undefined
            },

            oneTimeCodeResendTimerComputed() {
                return moment.unix(this.oneTimeCodeResendTimer).locale('ru').format('mm:ss')
            },
        },

        watch: {
            appBarData: {
                handler: function () {
                    this.setAppBarData()
                },
                immediate: true,
            }
        },

        methods: {
            ...mapMutations({
                setAppBar: 'ui/setAppBar',
            }),

            setAppBarData() {
                this.setAppBar(this.appBarData)
            },

            setStage(stage) {
                this.$refs.oneTimeCodeForm.reset()

                this.stage = stage
            },

            sendCode() {
                if (this.$refs.usernameForm.validate()) {
                    this.sendBtnDisabled = true
                    AuthService.register({
                        phone: this.clearUsername,
                    })
                        .then(response => {
                            this.sendBtnDisabled = false
                            this.setStage('oneTimeCode')
                        })
                        .catch(error => {
                            this.sendBtnDisabled = false
                            this.showToastError(error.errors)
                        });
                }
            },

            login() {
                if (this.$refs.oneTimeCodeForm.validate()) {
                    this.loginBtnDisabled = true
                    AuthService.makeLogin({
                        phone: this.clearUsername,
                        password: this.oneTimeCode,
                    })
                        .then(() => {
                            this.loginBtnDisabled = false
                            this.$router.push({ name: 'purchaseCatalog' })
                        })
                        .catch(error => {
                            this.loginBtnDisabled = false
                            this.showToastError(error.errors)
                        });
                }
            },
        },
    }
</script>
