<template>
    <v-toolbar
        :color="toolbarColor"
        light
    >
        <!-- Navigation icon -->
        <v-btn v-if="navigationIcon" icon @click="navigationIcon.click ? navigationIcon.click() : null">
            <AppVIcon :icon="navigationIcon.icon"/>
        </v-btn>

        <!-- Title -->
        <v-toolbar-title v-if="toolbarTitle">{{ toolbarTitle }}</v-toolbar-title>

        <v-spacer/>

        <!-- Action items -->

        <template v-if="toolbarActionItems && toolbarActionItems.length">
            <template v-for="(actionItem, actionItemIndex) in toolbarActionItems">

                <v-toolbar-items v-if="!!actionItem.text" :key="`toolbarActionItem_${actionItemIndex}`">
                    <v-btn
                        :disabled="actionItem.disabled || false"
                        text
                        @click.stop="actionItem.click ? actionItem.click() : null"
                    >
                        {{ actionItem.text }}
                    </v-btn>
                </v-toolbar-items>

                <v-btn
                    v-if="!!actionItem.icon"
                    :disabled="actionItem.disabled || false"
                    icon
                    :key="`toolbarActionItem_${actionItemIndex}`"
                    @click.stop="actionItem.click ? actionItem.click() : null"
                >
                    <AppVIcon :icon="actionItem.icon"/>
                </v-btn>

            </template>
        </template>
    </v-toolbar>
</template>

<script>
    import {
        VBtn,

        VSpacer,

        VToolbar,
        VToolbarItems,
        VToolbarTitle,
    } from 'vuetify/lib'
    import AppVIcon from '@/components/vuetify/Icon'

    export default {
        name: 'Toolbar',

        components: {
            VBtn,

            AppVIcon,

            VSpacer,

            VToolbar,
            VToolbarItems,
            VToolbarTitle,
        },

        props: {
            navigationIcon: { type: Object, default: null },
            title: { type: String, default: null },
            actionItems: { type: Array, default: null },
            // Theme
            color: { type: String, default: 'white' },
            // Contextual
            contextual: { type: Boolean, default: false },
            contextualTitle: { type: String, default: null },
            contextualActionItems: { type: Array, default: null },
        },

        data() {
            return {}
        },

        computed: {
            toolbarColor() {
                return this.contextual ? 'grey darken-4' : this.color
            },

            toolbarTitle() {
                return this.contextual ? this.contextualTitle : this.title
            },

            toolbarActionItems() {
                return this.contextual ? this.contextualActionItems : this.actionItems
            },
        },
    }
</script>
