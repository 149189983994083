<template>
    <v-dialog
        v-model="isShowedDialog"
        :fullscreen="dialogFullscreen"
        max-width="600px"
        :origin="dialogOrigin"
        :persistent="dialogPersistent"
        :scrollable="scrollable"
        :transition="dialogTransition"
    >
        <template v-if="type === 'alert'">
            <v-card>
                <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>

                <v-card-text>
                    <slot></slot>
                </v-card-text>

                <v-card-actions v-if="actionItems">
                    <v-spacer/>

                    <v-btn
                        v-for="actionItem in actionItems"
                        :color="actionItem.color ? actionItem.color : 'secondary'"
                        :key="`alertDialogActionItem_${actionItem.text}`"
                        text
                        @click="actionItem.click ? actionItem.click() : null"
                    >
                        {{ actionItem.text }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>

        <template v-if="type === 'simple'">
            <slot/>
        </template>

        <template v-if="type === 'confirmation'">
            <slot/>
        </template>

        <template v-if="type === 'full-screen'">
            <v-card>
                <AppVToolbar
                    :navigationIcon="toolbarNavigationIcon"
                    :title="title"
                    :action-items="actionItems"
                />
                <slot/>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
    import {
        VBtn,

        VCard,
        VCardText,
        VCardTitle,
        VCardActions,

        VDialog,

        VSpacer,
    } from 'vuetify/lib'
    import AppVToolbar from '@/components/vuetify/Toolbar'

    export default {
        name: 'Dialog',

        components: {
            AppVToolbar,

            VBtn,

            VCard,
            VCardText,
            VCardTitle,
            VCardActions,

            VDialog,

            VSpacer,
        },

        props: {
            type: { type: String, default: 'full-screen' }, // alert, simple, confirmation, full-screen
            isShowed: { type: Boolean, default: false },
            scrollable: { type: Boolean, default: false },
            toolbarNavigationIconAction: { type: Function, default: null },
            title: { type: String, default: null },
            actionItems: { type: [Array, Object], default: null },
        },

        data() {
            return {}
        },

        computed: {
            isShowedDialog: {
                get() {
                    return this.isShowed
                },
                set(value) {
                    this.$emit('update:isShowed', value)
                }
            },

            dialogFullscreen() {
                if (this.type === 'full-screen') {
                    return !this.$vuetify.breakpoint.mdAndUp
                }

                return false
            },

            dialogOrigin() {
                if (this.type === 'full-screen' && this.$vuetify.breakpoint.mdAndUp) {
                    return 'center center'
                } else {
                    return ''
                }
            },

            dialogPersistent() {
                return this.type === 'alert' || this.type === 'full-screen' && this.$vuetify.breakpoint.mdAndUp
            },

            dialogTransition() {
                if (this.type === 'full-screen' && !this.$vuetify.breakpoint.mdAndUp) {
                    return 'dialog-bottom-transition'
                } else {
                    return 'scale-transition'
                }
            },

            toolbarNavigationIcon() {
                return {
                    icon: 'close',
                    click: () => this.closeDialog(),
                }
            },
        },

        methods: {
            closeDialog() {
                this.isShowedDialog = false
            },
        },
    }
</script>
