<template>
    <div>
        Текст политики...
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicy',

        data() {
            return {}
        },
    }
</script>
